










































import { Component, Vue, Watch } from 'vue-property-decorator';
import ErrorAlert from '@/components/ErrorAlert.vue';
import { AxiosResponse } from 'axios';

@Component({
  components: { ErrorAlert }
})
export default class FinanceAccountExportDialog extends Vue {
  dialog: boolean = false;
  error: any = null;
  loading: boolean = false;

  file: string | null = null;

  @Watch('dialog')
  watchDialog(dialog: boolean) {
    if (this.file) {
      URL.revokeObjectURL(this.file);
    }

    if (!dialog) return;

    this.error = null;
    this.file = null;
    this.loading = false;
  }

  async save() {
    this.error = null;
    this.loading = true;

    try {
      const { data }: AxiosResponse<Blob> = await this.$http.get(`api/financial-administration/finance-accounts-pdf`, { responseType: 'blob' });

      this.file = URL.createObjectURL(data);
    } catch (error) {
      this.error = error;
    }

    this.loading = false;
  }
}
